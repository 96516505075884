const bloopLogo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABelBMVEUAAAADKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP8DKP////95zqauAAAAfHRSTlMA83VvZQkVGA8D/V4xhbvU2cqjXBb8wOf7yFEE8XkI+HwF8lnRHvba0+z+c1IaFDObwQ0rlOkscirk+Uj1PwpWQwzFfzTq9D4BGTs3E+Gp3h8GZskC3XEwKE+2qmHoUO0SDuIl666gbdxT18OVnZ6HSvrvLi2SxIQgbL+hagOBWwAAAAFiS0dEfaaxEMkAAAAHdElNRQfmCgQOAC4fU/0MAAABPklEQVQ4y2NgrAEDJgZcYGQpYCakgIWBgZWNnYMTlwIubh5ePn4BQSFhEewKRMXEISZJSEpJY7UCAWRk5fArqJFXUMSvoEZJmYCCGhVVbArU1DU0taBsbSwKBHV09fQN5CEcQyMMBfzGIA6rCYRnaoahwBzCs7AE86ys0RXY2EJ4doaQgLVHV6DmAOE5OkECVgfDCmcIz8UVzHNzZ2Dw8PTy9kEo8HUHyfv4QXj+AQyBQfxuSsFSIXBvhoaFR0RGiUI40YoMMW4gRmwcIqC44mPlYbFhz5AgCWE6YQ3qqEQGY4hjahixKUjyYmBIhgRITQpYgYQ8snwKKDLlUiEcIbCCtPQUhHxGZgjIQ95ZIE52BENObl6+p0eBUyFEukioGBpAJaVlhuUV8PDyrKyK57Lyr7ZmhQuFJDp6MDAAABQW3N3lakOFAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTEwLTA0VDE0OjAwOjQ1KzAwOjAwGF5yDgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0xMC0wNFQxNDowMDo0NSswMDowMGkDyrIAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjItMTAtMDRUMTQ6MDA6NDYrMDA6MDAP/vHwAAAAAElFTkSuQmCC"

const bloopDescription =
`
ChatGPT can't answer questions about private codebases, but bloop can. We've developed an AI chat assistant that can answer any question an engineer might have about a codebase. It turns out most engineers spend more time reading code than writing it, and our tool helps engineers easily navigate and understand unfamiliar codebases.

### What's the role?

We're looking to hire an exceptional contributor.

You'll be expected to move quickly in Rust, prototyping new ideas, evaluating the performance and promoting the experiments that work to production. You'll also be expected to leverage state of the art LLMs (GPT-4 as well as our own), full text search (with tantivy) and semantic search (with Qdrant) as well as bleeding edge static analysis techniques (with Stack graphs) for precise code navigation.

### Who are we looking for?

You hack around with GPT on the weekend, maybe even fine-tune some of these new open source models. But you're also a solid programmer, capable of not just prototyping a nice demo but also shipping performant code that can scale. You're probably quite good at what you do, maybe you were top of your class, or maybe you rose the ranks to your comfortable management role, but yearn for the days of writing code, redbull and deploying on a Friday. Whatever your background, you're excited to turn up to work and use the creativity and freedom afforded by an early stage startup to do your best work.

### Benefits and Perks
- Up to ~0.5% options
- Competitive salary
- 25 days holiday, in addition to bank holidays
- Top tier private medical insurance
- Quarterly offsites in exotic locations
- Flexible working, work from home 2 days per week

Send your resume to [bloop@rustjobs.dev](mailto:bloop@rustjobs.dev)
`

const bloop = {
  id: "bloop",
  name: "Bloop",
  logo: bloopLogo,
  description: bloopDescription,
  locations: ["GB"],
  link: "mailto:bloop@rustjobs.dev",
  size: "5+",
  bannerText: "Bloop is hiring in 🇬🇧!",
  bannerTextLong: "Bloop is hiring in London, United Kingdom 🇬🇧",
}

const scalaCompanies = {
}

const functionalCompanies = {
};
const kotlinCompanies = {};

const typeScriptCompanies = {
};

const rustCompanies = {
  bloop: bloop,
};

const wfhCompanies = {
};

const companies = process.env.WEBSITE === "functionaljobs" ? functionalCompanies :
  process.env.WEBSITE === "kotlinjobs" ? kotlinCompanies :
    process.env.WEBSITE === "typescriptjobs" ? typeScriptCompanies :
      process.env.WEBSITE === "wfhjobs" ? wfhCompanies :
        process.env.WEBSITE === "rustjobs" ? rustCompanies :
          scalaCompanies;

export default companies;